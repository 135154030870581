// CameraComponent.jsx
/*import React, { useRef, useCallback, useState } from 'react';
import Webcam from 'react-webcam';
import style from "./style.module.css";

const Camera = (props) => {
  const webcamRef = useRef(null);
  const [hasilFoto,setHasilFoto]=useState(null)
  const [isCameraOn, setIsCameraOn] = useState(true);

  const videoConstraints = {
    facingMode: 'environment', // Ganti ke 'user' untuk kamera depan
  };

  const capture = useCallback(() => {
    if (isCameraOn) {
      const imageSrc = webcamRef.current.getScreenshot();
      setHasilFoto(imageSrc);
      console.log(imageSrc);

      // Matikan kamera setelah mengambil foto
      setIsCameraOn(false);
    }
  }, [isCameraOn, webcamRef]);

  const toggleCamera = () => {
    setIsCameraOn((prevIsCameraOn) => !prevIsCameraOn);
  };

  return (
    <div className={style["containerCamera"]}>
      {isCameraOn && (
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
        />
      )}
      <button onClick={toggleCamera}>{isCameraOn ? 'Turn Off Camera' : 'Turn On Camera'}</button>
      <button onClick={capture}>Capture Photo</button>
      <div><img src={hasilFoto} alt="hasilFoto"/></div>
    </div>
  );
};

export default Camera;*/

// file Asli
import React, { useRef, useCallback, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import style from "./style.module.css";

const Camera = (props) => {
  const webcamRef = useRef(null);
  const [hasilFoto,setHasilFoto]=useState(null)
  const [isCameraOn, setIsCameraOn] = useState(true);
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  
  const videoConstraints = {
    facingMode: 'environment', // Ganti ke 'user' untuk kamera depan
    width: { ideal: 1280 },
  height: { ideal: 720 },
 // width: { ideal:window.innerHeight}, // malah tingginya hp
  //height: { ideal: window.innerWidth },// malah lebarnya hp
    //width: { ideal: (window.screen.height- (0.15 * window.screen.height)) },
    //height: { ideal: window.screen.width },
 
    //yang terakhir
    //width: screenHeight,
    //height: screenWidth,
    //aspectRatio: screenHeight / screenWidth,

  
   
    
  };

  const Capture = useCallback(() => {
    if (isCameraOn) {
      const imageSrc = webcamRef.current.getScreenshot();
  
     props.KlikFoto(imageSrc,props.idCamera);

      // Matikan kamera setelah mengambil foto
      setIsCameraOn(false);
    
    }
  }, [isCameraOn, webcamRef]);

  const Cancel=()=>{
    setIsCameraOn(false);
    props.Cancel();
  }
 

  return (
    <div className={style["containerCamera"]}>
      {isCameraOn && (
        <Webcam
        width="100%"
        height="auto"
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          screenshotQuality={1} // Sesuaikan nilai ini (0 hingga 1) untuk kualitas yang lebih baik
          videoConstraints={videoConstraints}
          
        />
      )}
      
      <button className={style["buttonCapture"]} onClick={Capture}>
        <span
                role="img"
                aria-label="Capture"
                style={{ fontSize: "30px" }}
              >
                📷
              </span></button>
      <button className={style["buttonCancel"]} onClick={Cancel}>Cancel</button>
    
    </div>
  );
};

export default Camera;

// Camera.js




