import React from "react";
import style from "./style.module.css";
import backgroundAQL from "../../Picture/MGM_Photo86.png";
import LogoMercindo from "../../Picture/logo_Mercindo.png";
import Button from "../Component/Button";
import { useEffect } from "react";

const Home=()=>{

  
    return(
        <div style={{backgroundImage:`url(${backgroundAQL})`}} className={style["backgroundImage"]} >
            
<div className={style["container"]}>
    <img src={LogoMercindo} alt="Logo Mercindo" className={style["img"]}/>
    <h1 className={style["bigWelcome"]}>SELAMAT DATANG</h1>
        <h1 className={style["statementWelcome"]}>
          Selamat datang di AQL Mobile Apps.<br></br>
          Pilih salah satu untuk melanjutkan
        </h1>
        <div className={style["button"]}>
<Button 
id="inputHome"
name="Input Laporan"
bC="rgb(0, 0, 161)"
width="48%"
height="70%"
color="white"
bR="10px"
border="none"
fS="25px"
display="flex"
jC="center"
aI="center"
 />
 <Button 
id="lihatHome"
name="Lihat Laporan"
bC="rgb(0, 0, 161)"
width="48%"
height="70%"
color="white"
bR="10px"
border="none"
fS="25px"
display="flex"
jC="center"
aI="center"
 />
        </div>
</div>
        </div>
    )
}

export default Home;



// App.jsx
/*import React from 'react';
import Camera from '../Component/Camera';

function App() {
  return (
    <div>
      <h1>Camera App</h1>
      <Camera/>
    </div>
  );
}

export default App;*/
// CameraApp.js

// sudah bisa download
/*import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';

const WebcamCapture = () => {
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);

    try {
      // Convert base64 image to blob
      const imageBlob = dataURItoBlob(imageSrc);

      // Create URL object for downloading the file
      const imageURL = URL.createObjectURL(imageBlob);

      // Create <a> element for downloading
      const a = document.createElement('a');
      a.href = imageURL;
      a.download = 'captured-image.jpg';
      a.click();

      // Release URL object after download
      URL.revokeObjectURL(imageURL);
    } catch (error) {
      console.error('Error during download:', error.message);
    }
  };

  // Function to convert base64 to blob
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: 'image/jpeg' });
  };

  return (
    <div>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        style={{ width: '100%', height: 'auto' }}
      />
      <button onClick={captureImage}>Capture Photo</button>
      {capturedImage && (
        <div id="captured-image" style={{ display: 'none' }}>
          <img src={capturedImage} alt="Captured" />
        </div>
      )}
    </div>
  );
};

export default WebcamCapture;*/

// sudah simpan di local storage
/*import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';

const WebcamCapture = () => {
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);

    try {
      // Simpan gambar di local storage
      saveImageToLocalStorage(imageSrc);
    } catch (error) {
      console.error('Error saving image to local storage:', error.message);
    }
  };

  const saveImageToLocalStorage = (imageSrc) => {
    // Buat key unik untuk menyimpan gambar
    const key = `captured-image-${Date.now()}`;

    // Simpan gambar di local storage dengan menggunakan key
    localStorage.setItem(key, imageSrc);

    // Optional: Jika Anda ingin menyimpan key di state atau melakukan sesuatu setelah menyimpan
    // setState({ savedImageKey: key });
  };

  return (
    <div>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        style={{ width: '100%', height: 'auto' }}
      />
      <button onClick={captureImage}>Capture Photo</button>
      {capturedImage && (
        <div id="captured-image" style={{ display: 'none' }}>
          <img src={capturedImage} alt="Captured" />
        </div>
      )}
    </div>
  );
};

export default WebcamCapture;*/








