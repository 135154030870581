import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.css";
import bg_judul from "../../../Picture/bg_Judul.png";
import logo_QC from "../../../Picture/logo_QC.png";
import Spinner from "../../Component/Spinner";
import logo_tambah from "../../../Picture/tambah.png";
import Pembatas from "../../Component/Pembatas";
import FotoChoice from "../../Component/FotoChoice";
import Button from "../../Component/Button";
import Camera from "../../Component/Camera";
import ComponentLoading from "../../Component/Loading";
import { stripBasename } from "@remix-run/router";
import Input from "../../Component/Input";

const InputLaporan = () => {
  const [loading,setLoading]=useState("notVisibleLoading");
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [idCamera, setIdCamera] = useState("");
  const [keteranganKirim, setKeteranganKirim] = useState("");
  const [valueSpinner, setValueSpinner] = useState("Pilih");
  const [layarOpacity, setLayarOpacity] = useState("layarOpacityOff");
  const [listKotakData, setListKotakData] = useState([]);
  const [kotakSelectedCamera, setKotakSelectedCamera] = useState(
    "kotakSelectedCameraOff"
  );
  const [isPreviewing, setIsPreviewing] = useState(false);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [selectedCamera, setSelectedCamera] = useState("Belakang");
  const [photoDataCartonMarking, setPhotoDataCartonMarking] = useState(null);
  const [cekCartonMarking, setCekCartonMarking] = useState(null);
  const [borderFotoCartonMarking, setBorderFotoCartonMarking] =
    useState("divFotoCekOff");
  const [photoDataCartonBoxCondition, setPhotoDataCartonBoxCondition] =
    useState(null);
  const [cekCartonBoxCondition, setCekCartonBoxCondition] = useState(null);
  const [borderFotoCartonBoxCondition, setBorderFotoCartonBoxCondition] =
    useState("divFotoCekOff");
  const [photoDataEnvelope, setPhotoDataEnvelope] = useState(null);
  const [cekEnvelope, setCekEnvelope] = useState(null);
  const [borderFotoEnvelope, setBorderFotoEnvelope] = useState("divFotoCekOff");
  const [photoDataFoldingMethod, setPhotoDataFoldingMethod] = useState(null);
  const [cekFoldingMethod, setCekFoldingMethod] = useState(null);
  const [borderFotoFoldingMethod, setBorderFotoFoldingMethod] =
    useState("divFotoCekOff");
  const [photoDataUPC, setPhotoDataUPC] = useState(null);
  const [cekUPC, setCekUPC] = useState(null);
  const [borderFotoUPC, setBorderFotoUPC] = useState("divFotoCekOff");
  const [photoDataComments, setPhotoDataComments] = useState(null);
  const [cekComments, setCekComments] = useState(null);
  const [borderFotoComments, setBorderFotoComments] = useState("divFotoCekOff");
  const [photoDataResultAQL, setPhotoDataResultAQL] = useState(null);
  const [cekResultAQL, setCekResultAQL] = useState(null);
  const [borderFotoResultAQL, setBorderFotoResultAQL] =
    useState("divFotoCekOff");
  const [valueStyle, setValueStyle] = useState("a");
  const [valueColor, setValueColor] = useState("Pilih");
  const [valueSeason, setValueSeason] = useState("Pilih");
  const [valueLotStatus, setValueLotStatus] = useState("Pilih");
  const [valueJenisDefect, setValueJenisDefect] = useState("Pilih");
  const [valueCartonMarking, setValueCartonMarking] = useState("Pilih");
  const [valueCartonBoxCondition, setValueCartonBoxCondition] =
    useState("Pilih");
  const [valueEnvelope, setValueEnvelope] = useState("Pilih");
  const [valueFoldingMethod, setValueFoldingMethod] = useState("Pilih");
  const [valueUPC, setValueUPC] = useState("Pilih");
  const [valueComments, setValueComments] = useState("Pilih");
  const [valueResultAQL, setValueResultAQL] = useState("Pilih");
  const [hasilInput, setHasilInput] = useState("");
  const [valuePO, setValuePO] = useState("");

  // fungsi input nilai PO
  const ValInput=(val)=>{
    setValuePO(val);
  }
  // fungsi ketika spinner dipilih
  const Val = (val, id) => {
    switch (id) {
      case "style":
        setValueStyle(val);
        break;
      case "color":
        setValueColor(val);
        break;
      case "season":
        setValueSeason(val);
        break;
      case "lotStatus":
        setValueLotStatus(val);
        break;
      case "jenisDefect":
        setValueJenisDefect(val);
        break;
      case "cartonMarking":
        setValueCartonMarking(val);
        break;
      case "cartonBoxCondition":
        setValueCartonBoxCondition(val);
        break;
      case "envelope":
        setValueEnvelope(val);
        break;
      case "foldingMethod":
        setValueFoldingMethod(val);
        break;
      case "upc":
        setValueUPC(val);
        break;
      case "comments":
        setValueComments(val);
        break;
      case "resultAQL":
        setValueResultAQL(val);
        break;
      default:
        break;
    }
  };

  //fungsi loading
  const Loading=(val)=>{
    setLoading(val);
  }
  
  // fungsi setelah klik button kirim
  const HasilButton = () => {
    setListKotakData([]);
    setPhotoDataCartonMarking(null);
    setCekCartonMarking(null);
    setBorderFotoCartonMarking("divFotoCekOff");
    setPhotoDataCartonBoxCondition(null);
    setCekCartonBoxCondition(null);
    setBorderFotoCartonBoxCondition("divFotoCekOff");
    setPhotoDataEnvelope(null);
    setCekEnvelope(null);
    setBorderFotoEnvelope("divFotoCekOff");
    setPhotoDataFoldingMethod(null);
    setCekFoldingMethod(null);
    setBorderFotoFoldingMethod("divFotoCekOff");
    setPhotoDataUPC(null);
    setCekUPC(null);
    setBorderFotoUPC("divFotoCekOff");
    setPhotoDataComments(null);
    setCekComments(null);
    setBorderFotoComments("divFotoCekOff");
    setPhotoDataResultAQL(null);
    setCekResultAQL(null);
    setBorderFotoResultAQL("divFotoCekOff");
    setHasilInput("");
    setValueStyle("Pilih");
    setValueColor("Pilih");
    setValueSeason("Pilih");

    setValueLotStatus("Pilih");

    setValueJenisDefect("Pilih");

    setValueCartonMarking("Pilih");

    setValueCartonBoxCondition("Pilih");

    setValueEnvelope("Pilih");

    setValueFoldingMethod("Pilih");

    setValueUPC("Pilih");

    setValueComments("Pilih");
    setValueResultAQL("Pilih");
    setKeteranganKirim("Data telah terkirim");
    setTimeout(() => setKeteranganKirim(""), 3000);
  };

  // function kalau kamera di cancel
  const Cancel = () => {
    setIsCameraOn(false);
  };

  // function setelah camera di klik Foto
  const KlikFoto = (val, id) => {
    switch (id) {
      case "defect":
        let namaDefect = localStorage.getItem("jenisDefect");
        let entryDefect;
        let existingEntriesDefect;
        existingEntriesDefect = JSON.parse(
          localStorage.getItem("kumpulanDefect")
        );
        if (existingEntriesDefect == null) existingEntriesDefect = [];
        entryDefect = {
          photoDefect: val,
          keteranganJenisDefect: namaDefect,
        };

        // Save allEntries back to local storage

        existingEntriesDefect.push(entryDefect);
        localStorage.setItem(
          "kumpulanDefect",
          JSON.stringify(existingEntriesDefect)
        );
        // disini nanti yg dibuat switch

        setListKotakData(existingEntriesDefect);

        break;
      case "cartonMarking":
        let cekPilihanCartonMarking = localStorage.getItem("cartonMarking");
        setCekCartonMarking(cekPilihanCartonMarking);
        setPhotoDataCartonMarking(val);
        localStorage.setItem("fotoCartonMarking", val);
        setBorderFotoCartonMarking("divFotoCekOn");
        break;
      case "cartonBoxCondition":
        let cekPilihanCartonBoxCondition =
          localStorage.getItem("cartonBoxCondition");
        setCekCartonBoxCondition(cekPilihanCartonBoxCondition);
        setPhotoDataCartonBoxCondition(val);
        localStorage.setItem("fotoCartonBoxCondition", val);
        setBorderFotoCartonBoxCondition("divFotoCekOn");
        break;
      case "envelope":
        let cekPilihanEnvelope = localStorage.getItem("envelope");
        setCekEnvelope(cekPilihanEnvelope);
        setPhotoDataEnvelope(val);
        localStorage.setItem("fotoEnvelope", val);
        setBorderFotoEnvelope("divFotoCekOn");
        break;
      case "foldingMethod":
        let cekPilihanFoldingMethod = localStorage.getItem("foldingMethod");
        setCekFoldingMethod(cekPilihanFoldingMethod);
        setPhotoDataFoldingMethod(val);
        localStorage.setItem("fotoFoldingMethod", val);
        setBorderFotoFoldingMethod("divFotoCekOn");
        break;
      case "upc":
        let cekPilihanUPC = localStorage.getItem("upc");
        setCekUPC(cekPilihanUPC);
        setPhotoDataUPC(val);
        localStorage.setItem("fotoUPC", val);
        setBorderFotoUPC("divFotoCekOn");
        break;
      case "comments":
        let cekPilihanComments = localStorage.getItem("comments");
        setCekComments(cekPilihanComments);
        setPhotoDataComments(val);
        localStorage.setItem("fotoComments", val);
        setBorderFotoComments("divFotoCekOn");
        break;
      case "resultAQL":
        let cekPilihanResultAQL = localStorage.getItem("resultAQL");
        setCekResultAQL(cekPilihanResultAQL);
        setPhotoDataResultAQL(val);
        localStorage.setItem("fotoResultAQL", val);
        setBorderFotoResultAQL("divFotoCekOn");
        break;

      default:
        break;
    }

    setIsCameraOn(false);
  };
  // function membuka kamera
  const PanggilFunctionCamera = (id) => {
    setIdCamera(id);
    setIsCameraOn(true);
  };

  // useEffect
  useEffect(() => {
    localStorage.getItem("kumpulanDefect") !== null &&
      setListKotakData(JSON.parse(localStorage.getItem("kumpulanDefect")));
      if(localStorage.getItem("fotoCartonMarking") !== null){
        setCekCartonMarking(localStorage.getItem("cartonMarking"));
        setBorderFotoCartonMarking("divFotoCekOn");
        setPhotoDataCartonMarking(localStorage.getItem("fotoCartonMarking"));
      }
      if(localStorage.getItem("fotoCartonBoxCondition") !== null){
        setCekCartonBoxCondition(localStorage.getItem("cartonBoxCondition"));
        setBorderFotoCartonBoxCondition("divFotoCekOn");
        setPhotoDataCartonBoxCondition(localStorage.getItem("fotoCartonBoxCondition"));
      }
      if(localStorage.getItem("fotoEnvelope") !== null){
        setCekEnvelope(localStorage.getItem("envelope"));
        setBorderFotoEnvelope("divFotoCekOn");
        setPhotoDataEnvelope(localStorage.getItem("fotoEnvelope"));
      }
      if(localStorage.getItem("fotoFoldingMethod") !== null){
        setCekFoldingMethod(localStorage.getItem("foldingMethod"));
        setBorderFotoFoldingMethod("divFotoCekOn");
        setPhotoDataFoldingMethod(localStorage.getItem("fotoFoldingMethod"));
      }
      if(localStorage.getItem("fotoUPC") !== null){
        setCekUPC(localStorage.getItem("upc"));
        setBorderFotoUPC("divFotoCekOn");
        setPhotoDataUPC(localStorage.getItem("fotoUPC"));
      }
      if(localStorage.getItem("fotoResultAQL") !== null){
        setCekResultAQL(localStorage.getItem("resultAQL"));
        setBorderFotoResultAQL("divFotoCekOn");
        setPhotoDataResultAQL(localStorage.getItem("fotoResultAQL"));
      }
      if(localStorage.getItem("fotoComments") !== null){
        setCekComments(localStorage.getItem("comments"));
        setBorderFotoComments("divFotoCekOn");
        setPhotoDataComments(localStorage.getItem("fotoComments"));
      }
      if(localStorage.getItem("keterangan") !== null){
        setHasilInput(localStorage.getItem("keterangan"));
        
      }
      if(localStorage.getItem("inputPO") !== null){
        setValuePO(localStorage.getItem("inputPO"));
        
      }
  }, []);

  // return jsx
  return (
    <div className={style["container"]}>
      <div className={style[`${loading}`]}>
<ComponentLoading/>
      </div>
      <div className={style["judul"]}>
        <img
          src={bg_judul}
          alt="background judul"
          className={style["imgJudul"]}
        />
        <div className={style["kotakTulisanJudul"]}>
          <img src={logo_QC} alt="logo QC" className={style["imgLogoQC"]} />
          <div>Input Laporan</div>
        </div>
      </div>
      <div className={style["isi"]}>
        <div className={style["kotakStyle"]}>
          <div className={style["inputPO"]}>
<Input
id="inputPO"
nama = "PO" 
value={valuePO}
Val={ValInput}
/>
          </div>
          <div className={style["spinnerStyle"]}>
            <Spinner
              name="Style"
              MT="-3.1%"
              ML="5%"
              FSOption="18px"
              id="style"
              Val={Val}
              value={valueStyle}
            />
          </div>
          <div className={style["spinnerColor"]}>
            <Spinner
              name="Color"
              MT="-3.1%"
              ML="5%"
              FSOption="18px"
              id="color"
              Val={Val}
              value={valueColor}
            />
          </div>
          <div className={style["spinnerSeason"]}>
            <Spinner
              name="Season"
              MT="-3.1%"
              ML="5%"
              FSOption="18px"
              id="season"
              Val={Val}
              value={valueSeason}
            />
          </div>
          <div className={style["spinnerLotStatus"]}>
            <Spinner
              name="Lot Status"
              MT="-3.1%"
              ML="5%"
              FSOption="18px"
              id="lotStatus"
              Val={Val}
              value={valueLotStatus}
            />
          </div>
        </div>
        <Pembatas nama="Defect" />
        <div className={style["kotakDefect"]}>
          <div className={style["kotakSpinnerDefect"]}>
            <div className={style["spinnerJenisDefect"]}>
              <Spinner
                name="Jenis Defect"
                MT="-3.9%"
                ML="5%"
                FSOption="18px"
                id="jenisDefect"
                Val={Val}
                value={valueJenisDefect}
              />
            </div>
            <img
              className={style["logoTambahDefect"]}
              src={logo_tambah}
              alt="logo tambah Defect"
              onClick={() => PanggilFunctionCamera("defect")}
            />
          </div>
          <div className={style["containerFotoDefect"]}>
            {listKotakData.map((val, index) => (
              <div className={style["divFotoDefect"]} key={index}>
                {val.photoDefect && (
                  <img
                    className={style["hasilFotoDefect"]}
                    src={val.photoDefect}
                    alt={val.keteranganJenisDefect}
                  />
                )}
                <div className={style["hasilTulisanDefect"]}>
                  <div>{val.keteranganJenisDefect}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Pembatas nama="Carton Marking" />
        <FotoChoice
          id="cartonMarking"
          name="Carton Marking"
          KlikFoto={() => PanggilFunctionCamera("cartonMarking")}
          divFotoCek={borderFotoCartonMarking}
          photoData={photoDataCartonMarking}
          cek={cekCartonMarking}
          Val={Val}
          value={valueCartonMarking}
        />
        <Pembatas nama="Carton Box Condition" />
        <FotoChoice
          id="cartonBoxCondition"
          name="Carton Box Condition"
          KlikFoto={() => PanggilFunctionCamera("cartonBoxCondition")}
          divFotoCek={borderFotoCartonBoxCondition}
          photoData={photoDataCartonBoxCondition}
          cek={cekCartonBoxCondition}
          Val={Val}
          value={valueCartonBoxCondition}
        />
        <Pembatas nama="Envelope" />
        <FotoChoice
          id="envelope"
          name="Envelope"
          KlikFoto={() => PanggilFunctionCamera("envelope")}
          divFotoCek={borderFotoEnvelope}
          photoData={photoDataEnvelope}
          cek={cekEnvelope}
          Val={Val}
          value={valueEnvelope}
        />
        <Pembatas nama="Folding Method" />
        <FotoChoice
          id="foldingMethod"
          name="Folding Method"
          KlikFoto={() => PanggilFunctionCamera("foldingMethod")}
          divFotoCek={borderFotoFoldingMethod}
          photoData={photoDataFoldingMethod}
          cek={cekFoldingMethod}
          Val={Val}
          value={valueFoldingMethod}
        />
        <Pembatas nama="UPC/Sticker/Hangtag" />
        <FotoChoice
          id="upc"
          name="UPC/Sticker/Hangtag"
          KlikFoto={() => PanggilFunctionCamera("upc")}
          divFotoCek={borderFotoUPC}
          photoData={photoDataUPC}
          cek={cekUPC}
          Val={Val}
          value={valueUPC}
        />
        <Pembatas nama="Result AQL" />
        <FotoChoice
          id="resultAQL"
          name="Result AQL"
          KlikFoto={() => PanggilFunctionCamera("resultAQL")}
          divFotoCek={borderFotoResultAQL}
          photoData={photoDataResultAQL}
          cek={cekResultAQL}
          Val={Val}
          value={valueResultAQL}
        />
        <Pembatas nama="Comments" />
        <FotoChoice
          id="comments"
          name="Comments"
          KlikFoto={() => PanggilFunctionCamera("comments")}
          divFotoCek={borderFotoComments}
          photoData={photoDataComments}
          cek={cekComments}
          Val={Val}
          value={valueComments}
        />
        <div className={style["keterangan"]}>
          <div>Keterangan</div>
          <input
            value={hasilInput}
            onChange={(e) => {
              localStorage.setItem("keterangan", e.target.value);
              setHasilInput(e.target.value);
            }}
          />
        </div>
        <div className={style["kirim"]}>
          <Button
            id="kirimInputLaporan"
            name="Kirim"
            bC="rgb(0, 0, 161)"
            width="48%"
            height="80%"
            color="white"
            bR="10px"
            border="none"
            fS="25px"
            display="flex"
            jC="center"
            aI="center"
            HasilButton={HasilButton}
            Loading = {Loading}
          />
        </div>
        <div className={style["keteranganKirim"]}>{keteranganKirim}</div>
      </div>
      {isCameraOn && (
        <Camera Cancel={Cancel} KlikFoto={KlikFoto} idCamera={idCamera} />
      )}
    </div>
  );
};
export default InputLaporan;

//fungsi klik cancel
/* const KlikCancel = () => {
    setLayarOpacity("layarOpacityOff");
    setKotakSelectedCamera("kotakSelectedCameraOff");
    setIsPreviewing(false);

    // Hentikan streaming dari kamera
    const videoTracks = videoRef.current?.srcObject?.getTracks();
    if (videoTracks) {
      videoTracks.forEach((track) => track.stop());
    }

    // Kosongkan objek srcObject pada elemen video
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };*/

// fungsi menampilkan kotak pilihan camera, memilih kamera depan atau belakang
/*const BukaSelectedKamera = (id) => {
    setLayarOpacity("layarOpacityOn");
    setIdCamera(id);
    setKotakSelectedCamera("kotakSelectedCameraOn");
  };*/

// fungsi preview kamera foto
/*const StartPreview = async () => {
    try {
      console.log(navigator.mediaDevices.getSupportedConstraints());
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: selectedCamera === "Belakang" ? "environment" : "user",
          width: { ideal: 720 }, // Lebar gambar sesuai dengan lebar layout
          height: { ideal: 480 }, // Tinggi gambar dengan aspek rasio 16:9 (100% / 16 * 9)
          frameRate: { ideal: 24 }, // Frame rate
          focusMode: "continuous", // Mode fokus (contoh: continuous, manual, dll.)
          // Pengaturan kualitas gambar
          advanced: [
            { focusMode: "continuous" },
            { exposureMode: "continuous" },
            { whiteBalanceMode: "auto" },
            // Atau tambahkan pengaturan lainnya sesuai kebutuhan
          ],
        },
      });

      videoRef.current.srcObject = stream;
      setIsPreviewing(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };*/

// fungsi setelah klik foto
/*const TakePhoto = (jenisFoto) => {
    try {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      switch (jenisFoto) {
        case "defect":
          let namaDefect = localStorage.getItem("jenisDefect");
          let entryDefect;
          let existingEntriesDefect;
          existingEntriesDefect = JSON.parse(
            localStorage.getItem("kumpulanDefect")
          );
          if (existingEntriesDefect == null) existingEntriesDefect = [];
          entryDefect = {
            photoDefect: canvas.toDataURL("image/png"),
            keteranganJenisDefect: namaDefect,
          };

          // Save allEntries back to local storage

          existingEntriesDefect.push(entryDefect);
          localStorage.setItem(
            "kumpulanDefect",
            JSON.stringify(existingEntriesDefect)
          );
          // disini nanti yg dibuat switch
          console.log(existingEntriesDefect);
          setListKotakData(existingEntriesDefect);
          break;
        case "cartonMarking":
          let cekPilihanCartonMarking = localStorage.getItem("cartonMarking");
          setCekCartonMarking(cekPilihanCartonMarking);
          setPhotoDataCartonMarking(canvas.toDataURL("image/png"));
          localStorage.setItem(
            "fotoCartonMarking",
            canvas.toDataURL("image/png")
          );
          setBorderFotoCartonMarking("divFotoCekOn");
          break;
        case "cartonBoxCondition":
          let cekPilihanCartonBoxCondition =
            localStorage.getItem("cartonBoxCondition");
          setCekCartonBoxCondition(cekPilihanCartonBoxCondition);
          setPhotoDataCartonBoxCondition(canvas.toDataURL("image/png"));
          localStorage.setItem(
            "fotoCartonBoxCondition",
            canvas.toDataURL("image/png")
          );
          setBorderFotoCartonBoxCondition("divFotoCekOn");
          break;
        case "envelope":
          let cekPilihanEnvelope = localStorage.getItem("envelope");
          setCekEnvelope(cekPilihanEnvelope);
          setPhotoDataEnvelope(canvas.toDataURL("image/png"));
          localStorage.setItem("fotoEnvelope", canvas.toDataURL("image/png"));
          setBorderFotoEnvelope("divFotoCekOn");
          break;
        case "foldingMethod":
          let cekPilihanFoldingMethod = localStorage.getItem("foldingMethod");
          setCekFoldingMethod(cekPilihanFoldingMethod);
          setPhotoDataFoldingMethod(canvas.toDataURL("image/png"));
          localStorage.setItem(
            "fotoFoldingMethod",
            canvas.toDataURL("image/png")
          );
          setBorderFotoFoldingMethod("divFotoCekOn");
          break;
        case "upc":
          let cekPilihanUPC = localStorage.getItem("upc");
          setCekUPC(cekPilihanUPC);
          setPhotoDataUPC(canvas.toDataURL("image/png"));
          localStorage.setItem("fotoUPC", canvas.toDataURL("image/png"));
          setBorderFotoUPC("divFotoCekOn");
          break;
        case "comments":
          let cekPilihanComments = localStorage.getItem("comments");
          setCekComments(cekPilihanComments);
          setPhotoDataComments(canvas.toDataURL("image/png"));
          localStorage.setItem("fotoComments", canvas.toDataURL("image/png"));
          setBorderFotoComments("divFotoCekOn");
          break;
        default:
          break;
      }

      setLayarOpacity("layarOpacityOff");
      setKotakSelectedCamera("kotakSelectedCameraOff");
    } catch (error) {
      console.error("Error capturing photo:", error);
    } finally {
      setIsPreviewing(false);

      // Hentikan streaming dari kamera
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };*/

/*useEffect(() => {
    if (isPreviewing) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      const updatePreview = () => {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        if (isPreviewing) {
          requestAnimationFrame(updatePreview);
        }
      };

      updatePreview();
    }
  }, [isPreviewing]);*/

/*return (
    <div className={style["container"]}>
    <div className={style[`${layarOpacity}`]}></div>
      <div className={style[`${kotakSelectedCamera}`]}>
        <select
          style={{ width: "100%", height: "40px" }}
          onChange={(e) => setSelectedCamera(e.target.value)}
        >
          <option>Belakang</option>
          <option>Depan</option>
        </select>
        {!isPreviewing ? (
          <button
            style={{
              height: "600px",
              width: "100%",
              fontWeight: "bold",
              fontSize: "24px",
            }}
            onClick={StartPreview}
          >
            {" "}
            Start Preview
          </button>
        ) : (
          <div style={{ position: "relative",height:"600px",width:"100%"}}>
            <canvas
              ref={canvasRef}
              style={{ width: "100%", height: "600px",  }}
            />
            <button
              style={{
                position: "absolute",
                bottom: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                backgroundColor: "transparent",
                border: "2px solid white",
                cursor: "pointer",
              }}
              onClick={() => TakePhoto(`${idCamera}`)}
            >
              <span
                role="img"
                aria-label="Capture"
                style={{ fontSize: "30px" }}
              >
                📷
              </span>
            </button>
          </div>
        )}
        <video
          ref={videoRef}
          style={{ display: "none" }}
          autoPlay
          playsInline
        />
        <button
          onClick={KlikCancel}
          style={{ height: "40px", width: "50%", borderRadius: "8px" }}
        >
          Cancel
        </button>
      </div>

      <div className={style["judul"]}>
        <img
          src={bg_judul}
          alt="background judul"
          className={style["imgJudul"]}
        />
        <div className={style["kotakTulisanJudul"]}>
          <img src={logo_QC} alt="logo QC" className={style["imgLogoQC"]} />
          <div>Input Laporan</div>
        </div>
      </div>
      <div className={style["isi"]}>
        <div className={style["kotakStyle"]}>
          <div className={style["spinnerStyle"]}>
            <Spinner
              name="Style"
              MT="-3.1%"
              ML="5%"
              FSOption="18px"
              id="style"
              Val={Val}
              value={valueStyle}
            />
          </div>
          <div className={style["spinnerColor"]}>
            <Spinner
              name="Color"
              MT="-3.1%"
              ML="5%"
              FSOption="18px"
              id="color"
              Val={Val}
              value={valueColor}
            />
          </div>
          <div className={style["spinnerSeason"]}>
            <Spinner
              name="Season"
              MT="-3.1%"
              ML="5%"
              FSOption="18px"
              id="season"
              Val={Val}
              value={valueSeason}
            />
          </div>
          <div className={style["spinnerLotStatus"]}>
            <Spinner
              name="Lot Status"
              MT="-3.1%"
              ML="5%"
              FSOption="18px"
              id="lotStatus"
              Val={Val}
              value={valueLotStatus}
            />
          </div>
        </div>
        <Pembatas nama="Defect" />
        <div className={style["kotakDefect"]}>
          <div className={style["kotakSpinnerDefect"]}>
            <div className={style["spinnerJenisDefect"]}>
              <Spinner
                name="Jenis Defect"
                MT="-3.9%"
                ML="5%"
                FSOption="18px"
                id="jenisDefect"
                Val={Val}
                value={valueJenisDefect}
              />
            </div>
            <img
              className={style["logoTambahDefect"]}
              src={logo_tambah}
              alt="logo tambah Defect"
              onClick={() => BukaSelectedKamera("defect")}
            />
          </div>
          <div className={style["containerFotoDefect"]}>
            {listKotakData.map((val, index) => (
              <div className={style["divFotoDefect"]} key={index}>
                {val.photoDefect && (
                  <img
                    className={style["hasilFotoDefect"]}
                    src={val.photoDefect}
                    alt={val.keteranganJenisDefect}
                  />
                )}
                <div className={style["hasilTulisanDefect"]}>
                  <div>{val.keteranganJenisDefect}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Pembatas nama="Carton Marking" />
        <FotoChoice
          id="cartonMarking"
          name="Carton Marking"
          KlikFoto={() => BukaSelectedKamera("cartonMarking")}
          divFotoCek={borderFotoCartonMarking}
          photoData={photoDataCartonMarking}
          cek={cekCartonMarking}
          Val={Val}
          value={valueCartonMarking}
        />
        <Pembatas nama="Carton Box Condition" />
        <FotoChoice
          id="cartonBoxCondition"
          name="Carton Box Condition"
          KlikFoto={() => BukaSelectedKamera("cartonBoxCondition")}
          divFotoCek={borderFotoCartonBoxCondition}
          photoData={photoDataCartonBoxCondition}
          cek={cekCartonBoxCondition}
          Val={Val}
          value={valueCartonBoxCondition}
        />
        <Pembatas nama="Envelope" />
        <FotoChoice
          id="envelope"
          name="Envelope"
          KlikFoto={() => BukaSelectedKamera("envelope")}
          divFotoCek={borderFotoEnvelope}
          photoData={photoDataEnvelope}
          cek={cekEnvelope}
          Val={Val}
          value={valueEnvelope}
        />
        <Pembatas nama="Folding Method" />
        <FotoChoice
          id="foldingMethod"
          name="Folding Method"
          KlikFoto={() => BukaSelectedKamera("foldingMethod")}
          divFotoCek={borderFotoFoldingMethod}
          photoData={photoDataFoldingMethod}
          cek={cekFoldingMethod}
          Val={Val}
          value={valueFoldingMethod}
        />
        <Pembatas nama="UPC/Sticker/Hangtag" />
        <FotoChoice
          id="upc"
          name="UPC/Sticker/Hangtag"
          KlikFoto={() => BukaSelectedKamera("upc")}
          divFotoCek={borderFotoUPC}
          photoData={photoDataUPC}
          cek={cekUPC}
          Val={Val}
          value={valueUPC}
        />
        <Pembatas nama="Comments" />
        <FotoChoice
          id="comments"
          name="Comments"
          KlikFoto={() => BukaSelectedKamera("comments")}
          divFotoCek={borderFotoComments}
          photoData={photoDataComments}
          cek={cekComments}
          Val={Val}
          value={valueComments}
        />
        <div className={style["keterangan"]}>
          <div>Keterangan</div>
          <input
            value={hasilInput}
            onChange={(e) => {
              localStorage.setItem("keterangan", e.target.value);
              setHasilInput(e.target.value);
            }}
          />
        </div>
        <div className={style["kirim"]}>
          <Button
            id="kirimInputLaporan"
            name="Kirim"
            bC="rgb(0, 0, 161)"
            width="48%"
            height="80%"
            color="white"
            bR="10px"
            border="none"
            fS="25px"
            display="flex"
            jC="center"
            aI="center"
            HasilButton={HasilButton}
          />
        </div>
        <div className={style["keteranganKirim"]}>{keteranganKirim}</div>
      </div>
    </div>
  );
  */
