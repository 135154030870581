import React from "react";
import style from "./style.module.css";
const Input =(props)=>{
    const OnChange=(val)=>{
props.Val(val);
localStorage.setItem(`${props.id}`,val);
    }
return(
    <div className={style["containerAll"]} >
<input value={props.value} onChange={(e)=>OnChange(e.target.value)} />
<div>{props.nama}</div>        

    </div>
)
}
export default Input;