import React from "react";
import style from "./style.module.css";
const Loading =(props)=>{
return(
    <div className={style['opacity']} >
        <div className={style['loading']}>
            
        </div>
    </div>
)
}
export default Loading;