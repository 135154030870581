import React from "react";
import { useState } from "react";
import style from "./style.module.css";
 
const Tanggal=(props)=>{
    const [valueTgl,setValueTgl]=useState("");
    const SetTanggal=(val,id)=>{
        switch(id){
            case "startDate":
               let sD = val;
                let combineSD = `${sD} 00:00:00`;
                localStorage.setItem(`${id}`, combineSD )
              
                break;
                case "finishDate":
                let fD = val;
                let combineFD = `${fD} 23:59:59`;
                localStorage.setItem(`${id}`,  combineFD  )
                break;
            default:
                break;
        }
   val === ""&& localStorage.setItem(`${id}`, val );
        setValueTgl(val);
    }
return(
<div style={{borderRadius:props.bRContainer}} className={style["container"]}>
<input  type="date" value={valueTgl} onChange={(e)=>SetTanggal(e.target.value,props.id)}/>
<div style={{marginTop:props.mTDiv,marginLeft:props.mLDiv}}>{props.nama}</div>
</div>
)
}
export default Tanggal;