import React from "react";
import { BrowserRouter,Routes,Route } from "react-router-dom";
import Home from "./Pages/Home";
import InputLaporan from "./Pages/Input/Laporan";
import LihatLaporan from "./Pages/Lihat/Laporan";

const App=()=>{
return(
 <BrowserRouter >
 <Routes >
  <Route path={'/'} element={<Home/>}/>
  <Route path={'/Input/Laporan'} element={<InputLaporan/>}/> 
  <Route path={'/Lihat/Laporan'} element={<LihatLaporan/>}/>
 </Routes>
 </BrowserRouter>
)
}
export default App;







