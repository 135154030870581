import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import LogoMercindo from "../../../Picture/logo_Mercindo.png";
import Spinner from "../../Component/Spinner";
import Tanggal from "../../Component/Tanggal";
import LogoPanah from "../../../Picture/panah.png";
import Button from "../../Component/Button";
import LogoDecathlon from "../../../Picture/Decathlon_Logo.svg.png";
import LogoEiger from "../../../Picture/Eiger.jpg";
import LogoAMD from "../../../Picture/AMD.png";
import LogoCallaway from "../../../Picture/callaway.png";
import LogoFlexitog from "../../../Picture/Flexitog_logo.jpg";
import LogoIronclad from "../../../Picture/ironclad.png";
import LogoJR from "../../../Picture/JR286.png";
import LogoKalibre from "../../../Picture/kalibre.png";
import LogoMIC from "../../../Picture/MIC.png";
import LogoMOG from "../../../Picture/MOG.png";
import LogoSAMS from "../../../Picture/SAMS.png";
import LogoSwany from "../../../Picture/swany.png";
import LogoVERB from "../../../Picture/VERB.png";
import ComponentLoading from "../../Component/Loading";


const LihatLaporan = () => {
  const [dataHasilFind, setDataHasilFind] = useState([]);
const [keteranganFind,setKeteranganFind]=useState("");
const [totalDefect,setTotalDefect]=useState("");
const [fotoBesar, setFotoBesar] = useState(null);
const [loading,setLoading]=useState("notVisibleLoading");

// function memperbesar foto
const KlikFotoBesar=(val)=>{
  setFotoBesar(val);
  
}
//function hasil keterangan dari button find
const Keterangan=(val)=>{
if(val === "Silakan pilih dahulu"){
  setKeteranganFind(val);
}else{
  setKeteranganFind("");
}
setLoading("notVisibleLoading");
}

// function ketika loading
const Loading=()=>{
  setLoading("visibleLoading");
}
// function hasil button find
  const HasilButton=(val)=>{
    if (val !== null) {
      setLoading("notVisibleLoading");
      setDataHasilFind(val);
      setKeteranganFind("");
    }else {
      setLoading("notVisibleLoading");
      setDataHasilFind([]);
      setKeteranganFind("Data tidak ditemukan");
    }
  }
  //


// function cari total defect
const TotalDefect=(val,index)=>{
  let jenisDefectArray = [val.jenisDefect1,val.jenisDefect2,val.jenisDefect3,val.jenisDefect4,val.jenisDefect5,val.jenisDefect6,val.jenisDefect7,val.jenisDefect8,val.jenisDefect9,val.jenisDefect10];
  let jenisDefectArrayFilter = jenisDefectArray.filter((item)=>item!=="");
  return(<>Total Defect : {jenisDefectArrayFilter.length}{" "}pcs</>)
}
// function data horizontal defect
  const DataDivHorizontal = (val, index) => {
    let fotoDefectArray = [val.fotoDefect1,val.fotoDefect2,val.fotoDefect3,val.fotoDefect4,val.fotoDefect5,val.fotoDefect6,val.fotoDefect7,val.fotoDefect8,val.fotoDefect9,val.fotoDefect10];
    let jenisDefectArray = [val.jenisDefect1,val.jenisDefect2,val.jenisDefect3,val.jenisDefect4,val.jenisDefect5,val.jenisDefect6,val.jenisDefect7,val.jenisDefect8,val.jenisDefect9,val.jenisDefect10];
    let fotoDefectArrayFilter = fotoDefectArray.filter((item)=>item!=="");
    let jenisDefectArrayFilter = jenisDefectArray.filter((item)=>item!=="");
    console.log(jenisDefectArrayFilter);
    let disatukan = [];
    for (let i = 0; i < fotoDefectArrayFilter.length; i++) {
      disatukan.push({ foto: fotoDefectArrayFilter[i], jenis: jenisDefectArrayFilter[i] });
    }
    return disatukan.map((item, index) => (
      <div key={index} className={style["kotakDefect"]}>
        <div className={style["kotakDefectAtas"]}>
          <div>#{index + 1}</div>
          <div>{item.jenis}</div>
        </div>
        <div className={style["kotakDefectBawah"]} onClick={()=>KlikFotoBesar(item.foto)}>
          <img  src={item.foto} alt={item.jenis} />
        </div>
       
      </div>
    ));
  };
// function data hasil inspection pakaging
  const DataDivBawah = (val, index) => {
    return (
      <div className={style["divHasilFind2"]}>
        <div className={style["div1"]}>PACKING INSPECTION</div>
        <div className={style["div2"]}>
          <div>Carton Marking</div>
          <div>Carton Box Condition</div>
          <div>Envelope</div>
          <div>Folding Method</div>
          <div>UPC/Sticker/Hangtag</div>
          <div>Comments</div>
        </div>
        <div className={style["div3"]}>
          <div onClick={()=>KlikFotoBesar(val.fotoCartonMarking)}>
            <img src={val.fotoCartonMarking} alt="Foto Carton Marking" />
          </div>
          <div onClick={()=>KlikFotoBesar(val.fotoCartonBoxCondition)}>
            <img
              src={val.fotoCartonBoxCondition}
              alt="Foto Carton Box Condition"
            />
          </div>
          <div onClick={()=>KlikFotoBesar(val.fotoEnvelope)}>
            <img src={val.fotoEnvelope} alt="Foto Envelope " />
          </div>
          <div onClick={()=>KlikFotoBesar(val.fotoFoldingMethod)}>
            <img src={val.fotoFoldingMethod} alt="Foto Folding Method" />
          </div>
          <div onClick={()=>KlikFotoBesar(val.fotoUPC)}>
            <img src={val.fotoUPC} alt="Foto carton Marking" />
          </div>
          <div onClick={()=>KlikFotoBesar(val.fotoComments)}>
            <img src={val.fotoComments} alt="Foto carton Marking" />
          </div>
        </div>
        <div className={style["div4"]}>
          <div>Carton Markings/Dimensions correct/No Damage</div>
          <div>Quantity correctly packed/good use of space</div>
          <div>Artwork/Hanger/sticker is correct</div>
          <div>Packing/Folding/Stuffing method is correct</div>
          <div>Hang tag, UPC label, Price, color, barcode are correct</div>
          <div>{val.keterangan}</div>
        </div>
        <div className={style["div5"]}>
          <div>{val.cekCartonMarking}</div>
          <div>{val.cekCartonBoxCondition}</div>
          <div>{val.cekEnvelope}</div>
          <div>{val.cekFoldingMethod}</div>
          <div>{val.cekUPC}</div>
          <div>{val.cekComments}</div>
        </div>
      </div>
    );
  };

  // function Result AQL
const DataDivResultAQL=(val,index)=>{
  return(
    <div className={style["divResultAQL"]}>
<div className={style["judulResultAQL"]}>RESULT AQL</div>
<div className={style["dataResultAQL"]}>
  <img onClick={()=>KlikFotoBesar(val.fotoResultAQL)} src={val.fotoResultAQL} alt="foto result AQL"/>
  <div className={style["divPass"]}>
    <div>PASSED</div>
  <div>{val.cekResultAQL}</div>
   </div>
  
</div>
    </div>
  )
}

useEffect(()=>{
  localStorage.removeItem("selectStyleLihatLaporan");
  localStorage.removeItem("selectBrandLihatLaporan");
  localStorage.removeItem("selectPOLihatLaporan");
  localStorage.removeItem("startDate");
  localStorage.removeItem("finishDate");
},[])
  return (
    <div className={style["bigContainer"]}>
      <div className={style[`${loading}`]}>
<ComponentLoading/>
      </div>
       {fotoBesar && (
        <div className={style["containerPerbesarFotoDefect"]} onClick={() => setFotoBesar(null)}>
          <img src={fotoBesar} alt="fotoDefect" />
        </div>
      )}
      <div className={style["judul"]}>
        <img src={LogoMercindo} alt="Logo Mercindo" />
        <div className={style["tulisanTopGolf"]}>FINAL INSPECTION</div>
      </div>
      <div className={style["wadahFind"]}>
    
        <div className={style["find"]}>
          <Spinner
            name="Brand"
            MT="-6.7%"
            ML="7%"
            FSOption="12px"
            id="brandLihatLaporan"
         
          />
          

        </div>
        <div className={style["find"]}>
          <Spinner
            name="Style"
            MT="-6.7%"
            ML="7%"
            FSOption="12px"
            id="styleLihatLaporan"
           
          />
          

        </div>
        <div className={style["find"]}>
          <Spinner
            name="PO"
            MT="-6.7%"
            ML="7%"
            FSOption="12px"
            id="poLihatLaporan"
           
          />
          

        </div>
        
        <div className={style["find"]}>
         <Tanggal
         nama="Start Date"
         bRContainer="10px"
         mLDiv="7%"
         mTDiv="-7.3%"
         id="startDate"
         />
          

        </div>
        <div className={style["findImage"]}>
        <img src={LogoPanah} alt={"Logo Panah"}/>

        </div>
        <div className={style["find"]}>
         <Tanggal
         nama="Finish Date"
         bRContainer="10px"
         mLDiv="7%"
         mTDiv="-7.3%"
         id="finishDate"
         />
          

        </div>
        <div className={style["buttonFind"]}>
        <Button
            id="findLihatLaporan"
            name="Find"
            bC="rgb(0, 0, 161)"
            width="100%"
            height="100%"
            color="white"
            bR="10px"
            border="none"
            fS="25px"
            display="flex"
            jC="center"
            aI="center"
            HasilButton={HasilButton}
            Keterangan = {Keterangan}
            Loading={Loading}
          />
        </div>
        <div style={{color:"red"}} className={style["keteranganFind"]}>
       {keteranganFind}
        </div>
        
      </div>

      <div className={style["containerHasilFind"]}>
        {dataHasilFind.map((val, index) => (
          <div
            key={index}
            style={{
              backgroundColor: index % 2 === 0 ? "rgb(245, 245, 245)" : "white",
            }}
            className={style["divHasilFind"]}
          >
            <div className={style["divHasilFind1"]}>
              <div className={style["tulisanStyle"]}>
                <div>Style</div>
                <div>:</div>
                <div>{val.style}</div>
              </div>
              <div className={style["tulisanStyle"]}>
                <div>PO</div>
                <div>:</div>
                <div>{val.po}</div>
              </div>
              <div className={style["tulisanStyle"]}>
                <div>Color</div>
                <div>:</div>
                <div>{val.color}</div>
              </div>
              <div className={style["tulisanStyle"]}>
                <div>Season</div>
                <div>:</div>
                <div>{val.season}</div>
              </div>
              <div className={style["tulisanStyle"]}>
                <div>Tanggal</div>
                <div>:</div>
                <div>{val.timestamp}</div>
              </div>
              <div className={style["pembatasTulisanStyle"]}></div>
              <div className={style["div1"]}>GLOVE INSPECTION</div>
              <div className={style["tulisanLot"]}>
                Lot Status : {val.lotStatus}
              </div>
              <div className={style["tulisanTotalDefect"]}>
                {TotalDefect(val,index)}
              </div>

              <div className={style["divHorizontal"]}>
                {DataDivHorizontal(val, index)}
              </div>
            </div>

            {DataDivBawah(val, index)}
            {DataDivResultAQL(val, index)}

            <div className={style["pembatasLogoBrand"]}></div>
            <div className={style["divLogoBrand"]}>
              <div>
              <img src={LogoAMD} alt="Logo AMD" />
              <img src={LogoCallaway} alt="Logo Callaway" />
              <img src={LogoDecathlon} alt="Logo Decathlon" />
              <img src={LogoEiger} alt="Logo Eiger" />
              <img src={LogoFlexitog} alt="Logo Flexitog" />
              <img src={LogoIronclad} alt="Logo Ironclad" />
              <img src={LogoJR} alt="Logo JR" />
              <img src={LogoKalibre} alt="Logo Kalibre" />
              <img src={LogoMIC} alt="Logo MIC" />
              <img src={LogoMOG} alt="Logo MOG" />
              <img src={LogoSAMS} alt="Logo SAMS" />
              <img src={LogoSwany} alt="Logo Swany" />
              <img src={LogoVERB} alt="Logo Verb" />
                 </div>
              
             
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default LihatLaporan;
