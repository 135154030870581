import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import style from "./style.module.css";

const Spinner = (props) => {
  const [data, setData] = useState([]);

  const url = "https://owner.umart.site/aql.php";
  async function GetData(val) {
    await fetch(url + "/?op=get" + val)
      .then((response) => response.json())
      .then((json) => {
        let style = localStorage.getItem("style");
        let color = localStorage.getItem("color");
        let season = localStorage.getItem("season");
        if (style === null) style = "";
        if (color === null) color = "";
        if (season === null) season = "";
        if (style === "Pilih") style = "";
        if (color === "Pilih") color = "";
        if (season === "Pilih") season = "";

        switch (val) {
          case "style":
            localStorage.setItem("dataJson", JSON.stringify(json));
            const uniqueStyle = new Set(
              json
                .filter(
                  (item) =>
                    item.color.includes(color) && item.season.includes(season)
                )
                .map((item) => item.style)
            );
            const uniqueStyleArray = [...uniqueStyle];

            setData(uniqueStyleArray);
            break;
          case "color":
            const uniqueColor = new Set(
              json
                .filter(
                  (item) =>
                    item.style.includes(style) && item.season.includes(season)
                )
                .map((item) => item.color)
            );
            const uniqueColorArray = [...uniqueColor];
            setData(uniqueColorArray);
            break;
          case "season":
            const uniqueSeason = new Set(
              json
                .filter(
                  (item) =>
                    item.style.includes(style) && item.color.includes(color)
                )
                .map((item) => item.season)
            );
            const uniqueSeasonArray = [...uniqueSeason];
            setData(uniqueSeasonArray);
            break;
          case "lotStatus":
            setData(json.map((item) => item.status));
            localStorage.setItem("dataLotStatus",JSON.stringify(json));
            break;
          case "jenisDefect":
            setData(json.map((item) => item.defect));
            break;
            case "brandLihatLaporan":
              let brandLihatLaporanUnique = new Set(json.map((item)=>item.brand));
              let brandLihatLaporanArray = [...brandLihatLaporanUnique];
              brandLihatLaporanArray.unshift("All");
            setData(brandLihatLaporanArray);  
            break;
            case "styleLihatLaporan":
            
              if((localStorage.getItem("selectBrandLihatLaporan") !== null)||
              (localStorage.getItem("selectBrandLihatLaporan") !== "Pilih")||
              (localStorage.getItem("selectBrandLihatLaporan") !== "All")){
              let styleLihatLaporanUnique = new Set(json.filter((item)=>item.brand === localStorage.getItem("selectBrandLihatLaporan"))
              .map((item)=>item.style));
              let styleLihatLaporanArray = [...styleLihatLaporanUnique];
              
            setData(styleLihatLaporanArray);  }
            else{
              setData([]);
            }
            break;
            case "poLihatLaporan":
            
              if((localStorage.getItem("selectStyleLihatLaporan") !== null)||
              (localStorage.getItem("selectStyleLihatLaporan") !== "Pilih")){
              let poLihatLaporanUnique = new Set(json.filter((item)=>item.style === localStorage.getItem("selectStyleLihatLaporan"))
              .map((item)=>item.po));
              let poLihatLaporanArray = [...poLihatLaporanUnique];
       
            setData(poLihatLaporanArray);  }
            else{
              setData([]);
            }
            break;
       
          default:
            setData(json.map((item) => item.cek));
            localStorage.setItem("dataCek",JSON.stringify(json));
            break;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  

  // function milih spinner
  const ChangeSpinner = (val, id) => {
    switch (id) {
      case "style":
        localStorage.setItem(`${id}`, val);
        props.Val(val,id);
        const fungsiBrand = () => {
          let style = localStorage.getItem("style");

          let color = localStorage.getItem("color");
          let season = localStorage.getItem("season");
          if (style === null) style = "";
          if (color === null) color = "";
          if (season === null) season = "";
          if (style === "Pilih") style = "";
          if (color === "Pilih") color = "";
          if (season === "Pilih") season = "";

          let dataJson = JSON.parse(localStorage.getItem("dataJson"));
          let uniqueBrand = new Set(
            dataJson
              .filter(
                (item) =>
                  item.color.includes(color) &&
                  item.season.includes(season) &&
                  item.style.includes(style)
              )
              .map((item) => item.brand)
          );
          let uniqueBrandIndex0 = [...uniqueBrand][0];
          localStorage.setItem("brand", uniqueBrandIndex0);
        };
        setTimeout(fungsiBrand, 1000);

        break;
        case "brandLihatLaporan":
          localStorage.setItem("selectBrandLihatLaporan",val );
          if(val==="All"){
            localStorage.setItem("selectStyleLihatLaporan","Pilih" );
            localStorage.setItem("selectPOLihatLaporan","Pilih" );
          }
         // FBrandLihatLaporan(val);
        break;
        case "styleLihatLaporan":
          localStorage.setItem("selectStyleLihatLaporan",val );
         // FBrandLihatLaporan(val);
        break;
        case "poLihatLaporan":
          localStorage.setItem("selectPOLihatLaporan",val );
         // FBrandLihatLaporan(val);
        break;
      default:
        localStorage.setItem(`${id}`, val);
        props.Val(val,id);
        break;
    }
  };

  //Get data from local storage
const GetDataSpinnerFromEffect=(id)=>{
  let hasilDataJson = JSON.parse(localStorage.getItem("dataJson"));
 
  let hasilDataLotStatus = JSON.parse(localStorage.getItem("dataLotStatus"));
  let hasilDataCek = JSON.parse(localStorage.getItem("dataCek"));
  
switch(id){
          case "style":
            if(hasilDataJson!==null){
            const uniqueStyleLS = new Set(
              hasilDataJson
                .map((item) => item.style)
            );
            const uniqueStyleArrayLS = [...uniqueStyleLS];
   setData(uniqueStyleArrayLS);
            }
            break;
            case "color":
              if(hasilDataJson!==null){
              const uniqueColorLS = new Set(
                hasilDataJson
                  .map((item) => item.color)
              );
              const uniqueColorArrayLS = [...uniqueColorLS];
  
              setData(uniqueColorArrayLS);}
              
              break;
              case "season":
                if(hasilDataJson!==null){
                let uniqueSeasonLS = new Set(
                  hasilDataJson
                    .map((item) => item.season)
                );
                const uniqueSeasonArrayLS = [...uniqueSeasonLS];
    
                setData(uniqueSeasonArrayLS);}
            
                break;
                case "lotStatus":
                  if(hasilDataLotStatus!==null){
                  let uniqueLotStatusLS = new Set(
                    hasilDataLotStatus
                      .map((item) => item.status)
                  );
                  const uniqueLotStatusArrayLS = [...uniqueLotStatusLS];
      
                setData(uniqueLotStatusArrayLS);}
                break;
            default:
              if(hasilDataCek!==null){
              let uniqueCekLS = new Set(
                hasilDataCek
                  .map((item) => item.cek)
              );
              const uniqueCekArrayLS = [...uniqueCekLS];
  
              setData(uniqueCekArrayLS);}
              break;
          }
          if(localStorage.getItem(props.id) !== null){
          props.Val(localStorage.getItem(props.id),id);}
          
            
}

// use effect
  useEffect(()=>{
GetDataSpinnerFromEffect(props.id);
  },[])

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <select
    value={props.value}
        onChange={(e) => ChangeSpinner(e.target.value, props.id)}
        onFocus={() => GetData(props.id)}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          border: "solid 2px black",
          backgroundColor: "white",
          borderRadius: "10px",
          fontSize: props.FSOption,
        }}
      >
        <option>Pilih</option>
        

        {data.map((val, index) => (
          <option key={index}>{val}</option>
        ))}
      </select>
      <div
        style={{
          position: "absolute",
          top: 0,
          marginTop: props.MT,
          marginLeft: props.ML,
          fontSize: props.FSName,
          fontWeight: "bold",
          backgroundColor: "white",
        }}
      >
        {props.name}
      </div>
    </div>
  );
};
export default Spinner;

// function setelah memilih brand lihat laporan

 /* const FBrandLihatLaporan=(val)=>{
fetch(url+"/?op=getDataSpinnerBrandLihatLaporan",
{
  method:"post",
  headers:{"Content-Type":"application/x-www-form-urlencoded"},
  body: 
  "brand="+
localStorage.getItem("selectBrandLihatLaporan")
}
)
.then((response)=>response.json())
.then((json)=>{
  console.log(json);
  props.DataSpinner(json);
})
.catch((error) => {
  console.log(error);
});
  } */