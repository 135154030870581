import React from "react";
import style from "./style.module.css";

const Pembatas =(props)=>{
return(
    <div className={style["pembatasDefect"]}>
          <div></div>
          <div> {props.nama}</div>
        </div>
)
}

export default Pembatas;