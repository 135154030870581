import React from "react";
import style from "./style.module.css";
import Spinner from "../Spinner";
import logo_tambah from "../../../Picture/tambah.png";
import { useState } from "react";

const FotoChoice =(props)=>{
const [photoData,setData]=useState(null);
const [keteranganJenisDefect,setKeteranganJenisDefect]=useState(null);
const Val =(val,id)=>{
  props.Val(val,id);
}
return(
<div className={style["kotakan"]}>
          <div className={style["kotakSpinneran"]}>
            <div className={style["spinnerJenisan"]}>
              <Spinner
                name={props.name}
        
                MT="-3.9%"
                ML="5%"
                FSOption="18px"
                id={props.id}
                Val={Val}
                value={props.value}
              />
            </div>
            <span
            className={style["logoFoto"]}
                role="img"
                aria-label="Capture"
                style={{ fontSize: "30px" }}
                onClick={props.KlikFoto}
              >
                📷
              </span>
          </div>
          <div className={style["containerFotoCek"]}>
            <div className={style[props.divFotoCek]}>
          {props.photoData && (
                  <img
                    className={style["foto"]}
                    src={props.photoData}
                    alt={props.cek}
                  />
                )}
                <div className={style["hasilCek"]}>
                  <div>{props.cek}</div>
                </div>
                </div>
                </div>
        </div>
)
}
export default FotoChoice;