
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Button =(props)=>{
    const url = "https://owner.umart.site/aql.php";
 
  
    
    const navigate = useNavigate();
    // function input laporan
    
    const KirimInputLaporan=()=>{
        props.Loading("visibleLoading");
        const urlDefectArray = ["inputDefect1","inputDefect2","inputDefect3","inputDefect4","inputDefect5",
        "inputDefect6","inputDefect7","inputDefect8","inputDefect9","inputDefect10"];
      
        
        let kumpulanDefect = JSON.parse(localStorage.getItem("kumpulanDefect"));
        let fotoDefectString=null;
        let jenisDefectString=null;
        if(kumpulanDefect !==null){
        fotoDefectString = kumpulanDefect.map((item)=>item.photoDefect);
        jenisDefectString = kumpulanDefect.map((item)=>item.keteranganJenisDefect);
        //fotoDefectString = kumpulanDefect.map((item)=>item.photoDefect).join("@FotoDefect");
        //jenisDefectString = kumpulanDefect.map((item)=>item.keteranganJenisDefect).join("@JenisDefect");
        }
        fetch(url+"/?op=inputSampaiLotStatus",{
            method:"post",
            headers:{"Content-Type":"application/x-www-form-urlencoded"},
            body:
            "po="+
            localStorage.getItem("inputPO")+
            "&style="+
            localStorage.getItem("style")+
            "&brand="+
            localStorage.getItem("brand")+
            "&color="+
            localStorage.getItem("color")+
            "&season="+
            localStorage.getItem("season")+
            "&lotStatus="+
            localStorage.getItem("lotStatus")
        })
        .then((response)=>response.json())
        .then((json)=>BerhasilKirimSampaiLotStatus(json))

        // fungsi setelah berhasil kirim sampai Lot Status
        const BerhasilKirimSampaiLotStatus=(ID)=>{
            
            if(kumpulanDefect!==null){
for(let i=0;i<kumpulanDefect.length;i++){
    fetch(url+"/?op="+urlDefectArray[i],{
        method:"post",
        headers:{"Content-Type":"application/x-www-form-urlencoded"},
        body:
        "ID="+
        ID +
        "&fotoDefect="+
        encodeURIComponent(fotoDefectString[i])+
        "&jenisDefect="+
        jenisDefectString[i]
    })
    .then((response)=>response.json())
    .then((json)=>{
        if(kumpulanDefect.length - i === 1 ){
            BerhasilKirimSampaiJenisDefect(ID);
        }
    })
   
}
            }else{
                BerhasilKirimSampaiJenisDefect(ID);
            }
        }
       
     
     
    }

    //fungsi setelah berhasil kirim sampai jenis defect
    const BerhasilKirimSampaiJenisDefect=(ID)=>{
        fetch(url+"/?op=inputSampaiCekCartonBoxCondition",{
            method:"post",
            headers:{"Content-Type":"application/x-www-form-urlencoded"},
            body:
           "ID="+
           ID+
            "&fotoCartonMarking="+
            encodeURIComponent(localStorage.getItem("fotoCartonMarking"))+
            "&cekCartonMarking="+
            localStorage.getItem("cartonMarking")+
            "&fotoCartonBoxCondition="+
            encodeURIComponent(localStorage.getItem("fotoCartonBoxCondition"))+
            "&cekCartonBoxCondition="+
            localStorage.getItem("cartonBoxCondition")
        })
        .then((response)=>response.json())
        .then((json)=>BerhasilKirimSampaiCartonBoxCondition(ID))
    }

    // fungsi setelah berhasil kirim sampai carton box condition
    const BerhasilKirimSampaiCartonBoxCondition=(ID)=>{
        fetch(url+"/?op=inputSampaiCekFoldingMethod",{
            method:"post",
            headers:{"Content-Type":"application/x-www-form-urlencoded"},
            body:
           "ID="+
           ID+
            "&fotoEnvelope="+
            encodeURIComponent(localStorage.getItem("fotoEnvelope"))+
            "&cekEnvelope="+
            localStorage.getItem("envelope")+
            "&fotoFoldingMethod="+
            encodeURIComponent(localStorage.getItem("fotoFoldingMethod"))+
            "&cekFoldingMethod="+
            localStorage.getItem("foldingMethod")
        })
        .then((response)=>response.json())
        .then((json)=>BerhasilKirimSampaiFoldingMethod(ID))
    }

    // fungsi setelah berhasil kirim sampai folding method
    const BerhasilKirimSampaiFoldingMethod=(ID)=>{
        fetch(url+"/?op=inputSampaiCekResultAQL",{
            method:"post",
            headers:{"Content-Type":"application/x-www-form-urlencoded"},
            body:
           "ID="+
           ID+
            "&fotoUPC="+
            encodeURIComponent(localStorage.getItem("fotoUPC"))+
            "&cekUPC="+
            localStorage.getItem("upc")+
            "&fotoResultAQL="+
            encodeURIComponent(localStorage.getItem("fotoResultAQL"))+
            "&cekResultAQL="+
            localStorage.getItem("resultAQL")
        })
        .then((response)=>response.json())
        .then((json)=>BerhasilKirimSampaiResultAQL(ID))
    }

    // fungsi setelah berhasil kirim sampai result AQL
    const BerhasilKirimSampaiResultAQL=(ID)=>{
        fetch(url+"/?op=inputSampaiKeterangan",{
            method:"post",
            headers:{"Content-Type":"application/x-www-form-urlencoded"},
            body:
           "ID="+
           ID+
            "&fotoComments="+
            encodeURIComponent(localStorage.getItem("fotoComments"))+
            "&cekComments="+
            localStorage.getItem("comments")+
            "&keterangan="+
            localStorage.getItem("keterangan")
        })
        .then((response)=>response.json())
        .then((json)=>json === "Berhasil" && BerhasilKirim())
    }

    // fungsi ketika berhasil kirim inputan
    const BerhasilKirim=()=>{
        localStorage.removeItem("dataJson");
        localStorage.removeItem("style");
        localStorage.removeItem("brand");
        localStorage.removeItem("color");
        localStorage.removeItem("inputPO");
        localStorage.removeItem("season");
  
        localStorage.removeItem("lotStatus");
        localStorage.removeItem("jenisDefect");
        localStorage.removeItem("kumpulanDefect");
       
       
        localStorage.removeItem("fotoCartonMarking");
       
        localStorage.removeItem("cartonMarking");

       localStorage.removeItem("fotoCartonBoxCondition");
    
        localStorage.removeItem("cartonBoxCondition");
    
       localStorage.removeItem("fotoEnvelope");
    
        localStorage.removeItem("envelope");
  
        localStorage.removeItem("fotoFoldingMethod");
      
        localStorage.removeItem("foldingMethod");
       
       localStorage.removeItem("fotoUPC");
      
        localStorage.removeItem("upc");
      
       localStorage.removeItem("fotoComments");
       
        localStorage.removeItem("comments");
      
        localStorage.removeItem("keterangan");
        localStorage.removeItem("fotoResultAQL");
       
        localStorage.removeItem("resultAQL");
        props.HasilButton();
        props.Loading("notVisibleLoading");
    }

    // fungsi ketika melihat laporan
    const FindLihatLaporan=()=>{
        let x = localStorage.getItem("selectBrandLihatLaporan");
        let a = localStorage.getItem("selectStyleLihatLaporan");
        let b = localStorage.getItem("selectPOLihatLaporan");
        let y = localStorage.getItem("startDate");
        let z = localStorage.getItem("finishDate");
        
        if( x === null || x ==="Pilih" || y === null || y === "" || z === null || z === "" ){
            props.Keterangan("Silakan pilih dahulu");
            
        }else if(
            x === "All"
        ){
            fetch(url+"/?op=getDataBrandAll",{
                method:"post",
                headers:{"Content-Type":"application/x-www-form-urlencoded"},
                body:
                "startDate="+
                y+
                "&finishDate="+
                z
            })
            .then((response)=>response.json())
            .then((json)=>
          props.HasilButton(json))
        }
        else{
            if((a === null) || (a==="Pilih")){
                a = "";
            }
            if((b === null) || (b ==="Pilih")){
                b = "";
            }
            fetch(url+"/?op=getDataBrandSelect",{
                method:"post",
                headers:{"Content-Type":"application/x-www-form-urlencoded"},
                body:
                "brand="+
                x+
                "&startDate="+
                y+
                "&finishDate="+
                z+
                "&po="+
                b+
                "&style="+
                a
               
            })
            .then((response)=>response.json())
            .then((json)=>{
           
            props.HasilButton(json);})  
        }
    }
    // fungsi ketika button di klik
    const Klik =()=>{
switch(props.id){
    case "inputHome":
        navigate("/Input/Laporan");
        break;
        case "lihatHome":
        navigate("/Lihat/Laporan");
        break;
        case "kirimInputLaporan":
         KirimInputLaporan();   
        break;
        case "findLihatLaporan":
            props.Loading();
        FindLihatLaporan();
        break;
default: break;
}
    }
    return(
        <button onClick={Klik} style={{
        backgroundColor: props.bC,
        width: props.width,
        height: props.height,
        color: props.color,
        borderRadius: props.bR,
        fontSize: props.fS,
        border: props.border,
        display:props.display,
        justifyContent:props.jC,
        alignItems:props.aI
        }}>
{props.name}
        </button>
    )
}
export default Button;